<template>
	<div class="alert alert-warning border-0" role="alert" v-if="getDiff() > 0 && store.getCart.length > 0">
		<span class="small">Faltan {{ currencyFormatted(getDiff()) }} más para el envío gratuito</span>
		<div class="progress mt-2" role="progressbar" aria-label="Warning example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar text-bg-warning" :style="`width: ${getPercentage()}%`">{{getPercentage()}}%</div>
		</div>
	</div>
</template>

<script setup>
import { currencyFormatted } from '@/helpers/currency';
import { useCheckoutStore } from '@/stores/checkout';

const store = useCheckoutStore()

const getDiff = () => {
	const amount = (200 - store.getSubtotal)
	return amount > 0 ? amount : 0
}

const getPercentage = () => {
	const subtotal = store.getSubtotal;
	return subtotal <= 200 ? parseInt((subtotal / 200) * 100) : 100;
}
</script>