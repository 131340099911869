import { swalToast } from '@/helpers/swal';
import { useCheckoutStore } from '@/stores/checkout';
import server from '@/api/server'

export default function useCheckout() {
    const chStore = useCheckoutStore()

    const getShippingCost = () => {
        let shippingCost = 0

        if(parseFloat(chStore.getSubtotalIntern) <= 100) {
            shippingCost = 3.84
        } else if(parseFloat(chStore.getSubtotalIntern) <= 200) {
            shippingCost = 3
        } else {
            shippingCost = 0;
        }
        
        return parseFloat(shippingCost)
    }

    function buildOrder(customerId, billing, shipping, couponCode, notes = '') {
        let orderData = {
            customer_id: customerId, 
            set_paid: false,
            billing,
            shipping: billing,
            notes,
            line_items: [
                ...chStore.getCart.map(el => {
                    return { product_id: el.id, quantity: el.quantity }
                })
            ],
        }

        if(shipping) {
            const amount = getShippingCost()
            orderData.shipping_lines = [
                {
                    method_id: shipping.method_id,
                    method_title: shipping.method_title, 
                    total: amount.toString() || "0.00",
                    total_tax: (amount * 0.21).toString()
                }
            ]
        }

        if(couponCode) {
            orderData.coupon_lines = [
                {
                    code: couponCode
                }
            ]
        }

        return validateOrder(orderData)
    }

    async function cancelCheckout() {
        const order_id = chStore.getOrderPending.id
    
        try {
            await server.post(`/wc/v2/orders/${order_id}`, { status: 'cancelled' })
            chStore.clearCheckout()
        } catch(err) {
            swalToast(err, 'error')
            return null
        }
    }

    function validateOrder(orderData) {
        let error = {status: false, msg: ''}

        if(!orderData.shipping_lines)
            error = {status: true, msg: 'No se ha seleccionado un método de envío disponible.'}

        if(orderData.line_items.length <= 0)
            error = {status: true, msg: 'No tienes productos en el carrito.'}

        if(error.status) {
            swalToast(error.msg, 'error')
            return null
        }
        return orderData
    }

    return {
        buildOrder,
        cancelCheckout,
    }
}